form.type3 {
    position: relative;

    >.grid-container {
        width: 100%;
    }

    .depot-finder {
        width: 100%;
        overflow: hidden;
        pointer-events: auto;

        .depot-tabs {
            position: relative;
            display: flex;
            width: 100%;
            margin-left: 0;
            transition: margin-left 0.25s;

            &.step-results {
                margin-left: -100%;
                width: 200%;

                .selection, .results {
                    width: 50%;
                    min-width: 50%;
                }

                .results {
					position: relative;

                    .content {
                        max-height: none;
                        @include breakpoint(large) {
                            max-height: rem-calc(600);

							&::after {
								background-color: $white;
								content: "";
								display: block;
								height: 5rem;
								margin-left: -1rem;
								margin-right: -1rem;
								position: relative;
								pointer-events: none;
								z-index: 2;
							}
                        }
                    }

					@include breakpoint(large) {
						&::before {
							content: '';
							position: absolute;
							top: 76px;
							right: 2px;
							bottom: 2px;
							width: 16px;
							background: $white;
							opacity: 1;
							z-index: 5;
							transition: all 0.3s linear;
						}

						&:hover::before {
							opacity: 0;
							z-index: -1;
							transition: all 0.3s linear;
						}

						&::after {
							height: 7rem;
							position: absolute;
							bottom: 2px;
							left: 2.5%;
							right: 2.5%;
							pointer-events: none;
							background-image: linear-gradient(to bottom,
								rgba(255,255,255, 0),
								rgba(255,255,255, 1) 70%);
							content: "\2193";
							z-index: 1;
							font-size: 2.5rem;
							display: flex;
							align-items: flex-end;
							justify-content: flex-end;
							color: $primary-color;
							padding-right: 0.5rem;
						}
					}
                }
            }

            .selection {
                min-width: 100%;
            }

            .selection, .results {
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                background-color: $primary-color;
            }

            .results {
                padding: rem-calc(2);

                .content {
                    background-color: $white;
                    padding: 0 1.5rem;

                    .depot {
                        display: flex;
                        border-bottom: 2px solid $accent-color;
						padding: 1.5rem 0 2.5rem 0;
                        margin: 1rem 0;
                        flex-direction: column;
                        width: 100%;

                        &:first-child {
                            margin-top: 0;
                        }

                        &:last-child {
                            border-bottom: none;

							@include breakpoint(large) {
								padding-bottom: 0;
							}
                        }

						.address {
							display: flex;
							flex-direction: column;
							font-style: unset;

							h5 {
								font-size: 1.2rem;
							}

							> div {
								display: flex;
								flex-direction: column;
							}
						}

                        .information {
                            width: 100%;
							display: flex;
							flex-direction: column;
							margin-top: 1rem;

							@include breakpoint(medium) {
								flex-direction: row;
							}

							.contact-information {
								display: flex;
								flex-direction: column;
								width: 100%;

								> div {
									display: flex;
									flex-direction: row;

									&:nth-child(3n) {
										margin-top: 1rem;
									}
								}

								a {
									color: $accent-color;
									word-break: break-word;
								}

								span {
									display: block;
									min-width: 3rem;
									color: $primary-color;
								}
							}

							.image {
								max-width: 75%;
								align-self: center;
								margin-top: 2rem;

								@include breakpoint(medium) {
									align-self: unset;
									max-width: 30%;
									margin-top: unset;
								}
							}
                        }
                    }
                }
            }

            .head {
                display: flex;
                height: 4.1rem;
                width: 100%;
                color: $white;

                a, .du-icon-chevron-left {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: $white;
                    cursor: pointer;
                }

                a {
                    width: 100%;
                    justify-content: flex-start;
                }

                .du-icon-chevron-left {
                    justify-content: center;
                    padding: 1rem 1rem 1rem 1.5rem;
                }
            }

            .content {
                width: 100%;
                overflow-y: auto;

                @include breakpoint(large) {
                    max-height: rem-calc(600);
                    overflow-y: scroll;
                }

                .form-group {
                    width: 100%;
                    min-height: 4.5rem;
                }
            }

            .form-group > .input {
                position: relative;
                width: 100%;

                #plz {
                    font-size: rem-calc(25);
                    height: 3.7rem;
                    padding: 0 1rem;
                    margin: 0;
                    border-left: 0.2rem solid $primary-color;
                    border-right: 0.2rem solid $primary-color;
                    border-top: 0.1rem solid $primary-color;
                    border-bottom: none;

                    @include breakpoint(xlarge) {
                        border-top: 0.2rem solid $primary-color;
                    }
                }

                .du-icon-search {
                    position: absolute;
                    right: 0.2rem;
                    top: 0.2rem;
                    color: $black;
                    padding: 1.3rem;
                    font-size: rem-calc(20);
                    cursor: pointer;
                }
            }

            .form-check {
                width: 100%;
                margin: 0;
                border-bottom: 1px solid $white;

                &:last-child {
                    border-bottom: none;
                }

                > label {
                    width: 100%;
                    color: $white;
                    font-size: rem-calc(25);
                    text-transform: none;
                    margin: 0;
                    cursor: pointer;

                    > b {
                        padding: 1rem;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }

                    > span {
                        width: 100%;
                        padding: 1rem 3.25rem;
                        margin: 0;
                        cursor: pointer;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;

                        &:before, &:after {
                            display: none;
                        }
                    }

                    > .du-icon-chevron-right {
                        position: absolute;
                        right: 0;
                        top: 0;
                        padding: 1.7rem 1.25rem;
                        font-size: rem-calc(20);
                    }
                }
            }
        }
    }
}
