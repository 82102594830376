p, li {
	font-size: 1rem;
	@extend .font-rl;
}

.small, small {
	font-size: $paragraph-small;
}

a {
	transition: all 0.2s ease;

	&:hover {
		color: $secondary-color;
	}
}

a.more {
	@extend .font-bd;
	font-size: $paragraph-small;
	text-transform: uppercase;

	&:before {
		@extend .du-icon;
		content: "\e902";
		position: relative;
		top: 0.1rem;
		margin-right: 0.5rem;
		font-size: rem-calc(16px) !important;
		transition: all 0.2s ease;
	}

	&:hover {
		color: $secondary-color;

		&:before {
			margin-right: 0.75rem;
		}
	}
}