.c-slider {
	.slides {
		position: relative;
	}

	button.arrow {
		position: absolute;
		top: 0;
		bottom: 0;
		width: rem-calc(45px);
		height: rem-calc(45px);
		padding: 0;
		margin: auto;
		color: $white;
		background: $black;
		z-index: 1;

		&:hover {
			background: $primary-color;
		}

		&:before {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			margin: 0;
		}

		&.prev {
			left: rem-calc(15px);

			@include breakpoint(xxlarge) {
				left: -5rem;
			}
		}

		&.next {
			right: rem-calc(15px);

			@include breakpoint(xxlarge) {
				right: -5rem;
			}
		}

		&.slick-disabled {
			display: none;
		}
	}

	.slider-nav {
		width: calc(100% + #{rem-calc(10px)});
		margin: rem-calc(10px -10px 0 -10px);

		.slick-slide {
			> div {
				position: relative;
				margin: rem-calc(0 10px);
				cursor: pointer;

				&:after {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					border: rem-calc(1px) solid rgba($white, 0);
					transition: all 0.2s ease;
				}
			}

			&.slick-current {
				> div {
					&:after {
						border: rem-calc(4px) solid $secondary-color;
					}
				}
			}
		}

		.slick-track {
			margin: 0;
		}
	}
}