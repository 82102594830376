.c-stage-image {
	position: relative;
	background: $light-gray;

	.inner {
		position: relative;
		max-width: rem-calc(1920px);
		margin: auto;
	}
}
