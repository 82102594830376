.c-breadcrumb {
	padding-top: 1rem;
	margin-bottom: 0;
	background-color: $primary-color-light;

	@extend .header-font;

	.grid-container {
		margin-bottom: 0;
	}

	a {
		transition: all 0.2s ease;

		&:hover {
			text-decoration: none;
			color: $breadcrumbs-item-color-current;
		}
	}

	.breadcrumbs {
		@include breakpoint(medium) {
			margin-right: 6rem;
		}

		@include breakpoint(large) {
			margin-right: 10rem;
		}
	}

	// Icon
	.breadcrumbs li:not(:last-child)::after {
		@extend .du-icon;
		font-size: rem-calc(12px) !important;
	}
}
