.sitemap {
    width: 100%;
    padding: 0;
    margin: 3rem 0 2rem;

    @include breakpoint(large) {
        margin: 5rem 0;
    }

    h3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2.5rem;
        text-transform: uppercase;
        justify-content: center;

        @include breakpoint(medium) {
            flex-direction: row;
            justify-content: flex-start;
        }

        span {
            font-size: 2rem;

            &:before {
                display: block;
                padding: 0.5rem;
                margin-bottom: 1rem;
                color: $white;
                background-color: $primary-color;

                @include breakpoint(medium) {
                    display: inline;
                    margin-right: 1.5rem;
                    margin-bottom: 0;
                }
            }
        }
    }

    a {
        padding: 0.7rem 1rem;
        color: $black;

		&:hover {
			color: $secondary-color;
		}
    }

    .menu {
        margin-bottom: 1rem;
        margin-left: -1rem;
        padding: 0;

        @include breakpoint(large up) {
            margin-bottom: 0;
        }

        .is-dropdown-submenu-parent {
            li {
                a {
                    padding: 0.5rem 1rem;
                }
            }
        }

        &.small {
            padding: 0;
            margin-top: 0;

            @include breakpoint(large up) {
                margin-top: 1rem;
            }
        }
    }

    > .menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0;

        > li {
            width: 100%;
            margin-right: 0;

            .spacer-blue {
                display: block;
                width: 3rem;
                height: 0.5rem;
                margin: 0.7rem 1rem;
                background-color: $primary-color-light;
            }

            @include breakpoint(medium) {
                width: 48%;
            }

            @include breakpoint(large) {
                width: fit-content;
            }
        }
    }
}
