.download-list {
    .download {
        display: flex;
		flex-direction: column;
        position: relative;
        border-bottom: 1px solid $medium-gray;
        padding: 0.5rem 0;
        background-color: transparent;
        color: $black;
        transition-property: color, background-color;

		@include breakpoint(medium) {
			flex-direction: row;
		}

        &:hover {
            background-color: $primary-color;
            .manufacturer, .data-sheet {
                color: $white;
            }
        }

        span {
            width: 100%;
            padding: 0.3rem;
			word-break: break-all;

			@include breakpoint(medium) {
				width: 50%;
			}
        }

        .manufacturer {
            font-size: 0.9rem;
            color: $alert-color;

            @include breakpoint(medium) {
                font-size: 1rem;
                color: $black;
            }
        }

        &.head {
            font-weight: $global-weight-bold;
            border-bottom: 2px solid $primary-color;
            &:hover {
                background-color: transparent;
                color: $black;
            }
        }
    }

    h4 {
        padding: 0.75rem 0.3rem;
        border-bottom: 2px solid $primary-color;
        margin: 0;
    }

	.button {
		min-width: 8.25rem;
		margin: 0;
		padding: 0.8rem 1rem;
	}

	>form .button {
        margin: 0 0 rem-calc(25px) 0;
		min-width: rem-calc(220px);
    }
}
