.c-full-width-slider {
    background-color: $light-gray;

    picture {
        position: relative;
        display: block;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            display: block;
            background: rgba($black, 0.4);
        }

	    &.no-header {
		    &:after {
			    display: none;
		    }
	    }
    }

    .js-stage-slider {
        position: relative;
        max-width: rem-calc(1920px);
        margin: auto;
    }

    .item {
        position: relative;
    }

    .slick-slide {
        > div {
            display: flex;
        }
    }

    section {
        width: 100%;
        max-width: $global-width;
    }

    .cta {
        text-align: left;
        padding: 0;

        @include breakpoint(xlarge) {
            padding: 1.5rem 0 1rem 0;
        }

        button {
            margin-bottom: 0;

            @include breakpoint(medium) {
                margin-bottom: 1rem;
            }
        }
    }

    .arrow {
        display: none !important;
        cursor: pointer;
        position: absolute;
        top: calc(50% - #{rem-calc(20)});
        z-index: $z-index-arrows;
        width: rem-calc(40);
        height: rem-calc(40);
        border-color: $white;
        color: $white;
        padding: 0;


        &:before {
            margin: 0;
        }

        &:active, &:focus, &:hover {
            background-color: transparent;
        }

        &.prev {
            left: rem-calc(15);
        }

        &.next {
            right: rem-calc(15);
        }
    }

    .dots {
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }


    &:not(.c-stage-slider) {
        picture {
            &:after {
                @include breakpoint(xlarge) {
                    background: linear-gradient(to right, rgba($black, 0.7) 0%, rgba($black, 0) 80%);
                }
            }
        }

        section {
            @include breakpoint(large) {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }

        .arrow {
            @include breakpoint(large) {
                display: inline-block !important;
            }
        }

        ul.slick-dots > li button {
            background-color: $primary-color;
        }

        .content {
            width: 100%;

            @include breakpoint(large) {
                padding: 0 4rem;
            }

            @include breakpoint(xxlarge) {
                padding: 0;
            }
        }

        .cta .button {
            @include breakpoint(large) {
                @include button-color($white, $white, $primary-color);
            }
        }

        h2 {
            padding-top: rem-calc(40);
            color: $primary-color;
            text-transform: uppercase;
            text-align: left;


            @include breakpoint(large) {
                color: $white;
            }
        }

        p {
            color: $body-font-color;

            @include breakpoint(large) {
                color: $white;
            }
        }
    }
}
