// generel menu styles

// Main Menu / First Layer
.menu {
    position: static;

    a {
        transition: all 0.2s ease;
    }

    li {
        text-transform: uppercase;
        font-weight: $header-font-weight;
    }

    .is-dropdown-submenu-parent {
        .menu {
            margin: 0;

            a {
                text-transform: none;
                font-weight: $global-weight-normal;
            }
        }
    }
}
