header.main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: $z-index-menu;
    background-color: $white;

    @include breakpoint(large) {
        border-bottom: 1px solid $black;
    }
}

.is-reveal-open header.main {
    border-color: transparent;
}
