.cta {
    flex-direction: row;
    justify-content: space-between;

    >.grid-x {
        width: calc(100% + #{rem-calc(20px)});

        @include breakpoint(medium) {
            width: calc(100% + #{rem-calc(30px)});
        }
    }

    &, &-item {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &-item {
        flex-direction: column;
        justify-content: center;
        background-color: $light-gray;
        padding: 2rem;
        margin-bottom: 15px;
    }

    h2 {
        line-height: 1;
        margin: 0;
    }

    p {
        color: $primary-color;
        font-size: 1.1rem;
        text-transform: uppercase;
    }

    img {
        margin-bottom: 1.5rem;
    }
}