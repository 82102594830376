.c-logo {
    z-index: $z-index-logo;
    position: relative;


    a {
        display: block;
        padding: 1rem;
        background-color: $primary-color;

        @include breakpoint(large) {
            padding: 8rem 2rem 2rem 2rem;
        }

        img {
            width: 3rem;

            @include breakpoint(large) {
                width: auto;
            }
        }
    }
}

.content-logo {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(20);

    > span {
        display: block;
        width: rem-calc(56);
        height: rem-calc(56);
        padding: rem-calc(10);
        font-size: rem-calc(36px);
        color: $white;
        background: $primary-color;
    }

    h4 {
        margin: 0 0 0 1rem;
    }
}