.c-footer {
    .menu {
        width: 100%;
        padding: 0;
    }

    nav.misc {
        margin: 0 0 2rem 0;
        text-align: center;

        @include breakpoint(medium) {
            margin: 0;
            text-align: left;
        }

        a {
            @extend .header-font;
            padding: 0.5rem 0;

            @include breakpoint(medium) {
                margin-right: 1.5rem;
            }

            @include breakpoint(large) {
                margin-right: 3rem;
            }
        }
    }
}