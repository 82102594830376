.button {
    border: 2px solid $black;
    text-transform: uppercase;
    font-weight: $global-weight-bold;
    font-size: 1rem;

	border-color: $primary-color;
	border-radius: 0.5rem;
	color: $white;
	box-shadow: -300px -50px 0 0 $primary-color inset, 0 0 0 0 $primary-color;
	transition: all 250ms ease-in-out;

	&:hover {
		box-shadow: 0 0 0 0 $primary-color inset, 0 0 0 0 $primary-color;
		color: $primary-color;
		background-color: transparent;
		border-color: rgba($primary-color, 0.8);
	}

    &.negative,
    &.color-white {
        @include button-color($white, $white, $primary-color);
    }

    [class*='du-icon-arrow'], .du-icon-close {
        margin-left: 1rem;
    }

    &-close {
        width: 100%;
        padding: 0.6rem 1rem;
        font-size: 0.9rem;
        font-weight: $global-weight-light;
        background-color: $black;
        color: $white;
        cursor: pointer;
        text-transform: uppercase;
    }

    &.with-icon {
        &:after {
            @extend .du-icon;
            content: "\e902";
            margin-left: 1rem;
        }
    }

	&.with-icon-previous {
		&:before {
			@extend .du-icon;
			content: "\e901";
			margin-right: 1rem;
		}
	}
}
