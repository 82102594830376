.ce-uploads {
	.size,
	.type {
		min-width: 10%;
		white-space: nowrap;

		@include breakpoint(large) {
			width: auto;
		}
	}

	.icon,
	.thumb {
		min-width: rem-calc(100px);
	}

	.name {
		min-width: 30%;
		white-space: nowrap;
		text-overflow: ellipsis;

		@include breakpoint(large) {
			min-width: 20%;
		}
	}

	.description {
		min-width: 30%;

		@include breakpoint(large) {
			min-width: 40%;
		}
	}
}
