.to-top-button {
    position: fixed;
    right: rem-calc(13);
    bottom: rem-calc(13);
    justify-content: center;
    align-items: center;
    display: flex;
    width: rem-calc(44);
    height: rem-calc(44);
    border: rem-calc(2) solid $white;
    color: $white;
    background: $primary-color;
    opacity: 0;
    z-index: $z-index-logo;

    @include breakpoint(large) {
        right: rem-calc(58);
        bottom: rem-calc(18);
    }

    @include breakpoint(xlarge) {
        width: rem-calc(54);
        height: rem-calc(54);
    }

    &.show {
        opacity: 1;
    }

    span {
        font-size: 1rem;

        @include breakpoint(xlarge) {
            font-size: 1.25rem;
        }
    }

    &:focus {
        color: $white;
        background: $primary-color;
    }

    &:hover,
    &:focus:hover {
        color: $white;
        
        @include breakpoint(large) {
            background: $secondary-color;
        }
    }
}