.c-testimonial {
	text-align: center;

	cite,
	blockquote {
		text-transform: uppercase;
	}

	cite {
		font-style: normal;
		margin-bottom: rem-calc(24px);

		@include breakpoint(medium) {
			font-size: rem-calc(22px);
		}
	}

	blockquote {
		@extend .h2;
	}


}

