// generel menu styles

// Main Menu / First Layer
.menu {
    &.mainmenu {
        margin-right: 4rem;

        > li {
            > a {
                @extend .header-font;
            }

            &:first-child > a {
                @include breakpoint(large) {
                    padding-left: 0;
                }

            }
            .button-close {
				bottom: 2.5rem;
				right: 2.5rem;
				position: absolute;
                width: auto;
            }
        }
    }

    &.menu-mobile {
        > li {
            flex-direction: column;

            >button {
                position: absolute;
            }

            a {
                width: 100%;
            }
        }
    }

    button.clear.button {
        padding: 0.7rem 1rem;
		box-shadow: none;

        @include breakpoint(large up) {
            padding: 0.7rem 0.5rem;
        }

        span:before {
            font-size: 1rem;
        }

		&:hover {
			color: $secondary-color;
		}
    }

    > a {
        @extend .header-font;
    }

    li {
        text-transform: uppercase;
        font-weight: $header-font-weight;

        &.active > a {
            color: $secondary-color;
            background-color: transparent;
        }
    }

    .is-dropdown-submenu-parent {
        .menu {
            margin: 0;

            a {
                text-transform: none;
                font-weight: $global-weight-normal;
            }
        }
    }

    >li > button{
        position: relative;

        &.open {
            color: $menu-item-color-active;

            &:before {
                opacity: 1;
            }
        }

        &:before {
            opacity: 0;
            position: absolute;
            bottom: -2.2rem;
            left: calc(50% - 1.8rem);
            width: 0;
            height: 0;
            content: "";
            -webkit-transform: rotate(360deg);
            border-style: solid;
            border-width: 0 1.8rem 1.5rem 1.8rem;
            border-color: transparent transparent $light-gray transparent;
            /*transition: opacity 0.5s;*/
        }
    }

    .sub {
        > a {
            position: relative;

            &:before {
                opacity: 0;
                position: absolute;
                bottom: -2.3rem;
                left: calc(50% - 1.8rem);
                width: 0;
                height: 0;
                content: "";
                -webkit-transform: rotate(360deg);
                border-style: solid;
                border-width: 0 1.8rem 1.5rem 1.8rem;
                border-color: transparent transparent $light-gray transparent;
                transition: opacity 0.5s;
            }
		}

        &.open {
            > a:before {
                opacity: 1;
            }
        }

        .content {
            display: none;
            position: absolute;
            top: 6.7rem;
            right: 0;
            bottom: auto;
            left: 0;
            width: 100%;
            height: calc(100vh - 6.7rem);
            max-width: $global-max-width;
            margin: auto;
            padding: 5rem 0;
            background-color: rgba($light-gray,0.8);
            z-index: $z-index-submenu;
            border-bottom: 1px solid $black;

            .grid-container {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: flex-start;
                margin-bottom: 0;

                > .grid-x {
                    width: 100%;
					position: relative;
					padding-bottom: 2.5rem;
					border-bottom: 2px solid $primary-color;
                }

                .description {
                    text-transform: none;
					border-right: 2px solid $primary-color;
					padding-right: 3rem;

                    p {
                        font-weight: normal;
                    }

                    a {
                        display: inline-block;
                    }

                    .button {
                        font-size: 0.9rem;
					}

					.spacer-blue {
						margin: 0.7rem 0;
					}
                }

                .sub-menu {
                    flex-wrap: wrap;

                    li {
                        width: 100%;
						padding-top: 0.5rem;

                        @include breakpoint(xlarge) {
                            width: 50%;
                        }
                    }

                    a {
                        padding: 0;
						margin-bottom: 1.5rem;
						text-transform: initial;
                    }
                }
            }
        }

		.subLink {
			padding: 0;
		}
    }
}

#top-bar ul {
    background-color: transparent;
}
