.spacer-blue {
    display: block;
    width: 3rem;
    height: 0.5rem;
    margin: 0.7rem 1rem;
    background-color: $primary-color-light;
    content: '';

    &-large {
        @extend .spacer-blue;
        width: 5rem;
        height: 0.4rem;
        margin: 1.5rem 1.5rem 1.5rem 0;
    }

    &-xlarge {
        @extend .spacer-blue;
        width: rem-calc(125);
        height: rem-calc(12);
        margin: rem-calc(30) 0 rem-calc(40) 0;
    }

    &.center {
        margin-right: auto;
        margin-left: auto;
    }
}