.c-stage-text {
	padding: rem-calc(30px 0 20px 0);
	background-color: $light-gray;

	@include breakpoint(medium) {
		padding: rem-calc(50px 0 40px 0);
	}

	p {
		margin-bottom: 0;
	}
}
