.no-list-styles {}

.menu,
.breadcrumbs,
.ce-uploads,
.tabs,
.accordion,
.sidebar,
.slick-dots,
.pagination {
	@extend .no-list-styles;
}

ul:not(.no-list-styles),
ol {
	> li {
		position: relative;
		margin-bottom: 0.5rem;

		&:before {
			position: absolute;
			left: -$list-side-margin;
		}

		> ul:not(.no-list-styles),
		> ol {
			margin-top: 0.5rem;
		}
	}
}

ul:not(.no-list-styles) {
	list-style: none;

	> li {
		&:before {
			content: '';
			top: rem-calc(11px);
			display: block;
			width: rem-calc(10px);
			height: rem-calc(10px);
			background: $primary-color;
		}

		ul:not(.no-list-styles) > li:before {
			background: $primary-color-light;
		}
	}
}

ol {
	list-style: none;
	counter-reset: li;

	> li {
		counter-increment: li;

		&:before {
			content: counter(li, decimal) ".";
			color: $body-font-color;
		}
	}
}