h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	@extend .header-font;

}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
	color: $primary-color;
}

h1, .h1 {
	line-height: 1;
	margin-bottom: rem-calc(30px);
	text-transform: uppercase;
}

h2, .h2 {
	line-height: 1.1;
}

h3, .h3 {
	margin-bottom: rem-calc(25px);
}

h4, .h4 {

}


h2, h3, h4,
.h2, .h3, .h4 {
	&.special {
		text-transform: uppercase;
		line-height: 1.2;
	}
}

header.hgroup {
	h2, h3, h4,
	.h2, .h3, .h4 {
		@extend .special;
	}

	h2, .h2,
	h3, .h3 {
		+ h3, + .h3,
		+ h4, + .h4 {
			font-family: $body-font-family;
			font-weight: $global-weight-normal;
			text-transform: uppercase;
			font-size: rem-calc(18px);
			line-height: 1;

			@include breakpoint(medium) {
				font-size: rem-calc(22px);
			}

			&:after {
				content: '';
				@extend .spacer-blue-large;
			}
		}
	}
}