.container {
	@include flex;
	width: 100%;
	max-width: $global-width;
    margin: auto;
}

.flex {
	@include flex;
	width: 100%;
}

html {
	height: 100%;
	background-color: $light-gray;
}

html, body {
	overflow-x: hidden;
}

body {
	max-width: $global-max-width;
	margin: 0 auto;

	&.overlay-static {
		position: fixed;
		left: 0;
		right: 0;
		width: 100%;
		max-height: 100%;
		overflow: hidden;

		@include breakpoint(large) {
			max-height: none;
			position: relative;
			overflow: auto;
		}
	}

	&.is-reveal-open {
		overflow: hidden;
		height: 100%;
	}
}
