#nav-lang {
    align-items: center;
    overflow: scroll;
	padding: 5rem 1rem;

    h2 {
        margin-bottom: 1rem;

        @include breakpoint(medium up) {
            margin-bottom: 4rem;
        }
    }

    .menu {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: $global-width;
        flex-wrap: wrap;
        min-height: 18rem;
        margin-bottom: 3rem;

        @include breakpoint(medium up) {
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            min-height: 8rem;
        }

        li {
			padding: 0.5rem 0;

            @include breakpoint(medium up) {
                padding: 1rem 0;
            }

			&:last-child {
				padding: 0.5rem 0 3.5rem;

				@include breakpoint(medium up) {
					padding: 1rem 0;
				}
			}

            &, a {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
				color: $white;

                @include breakpoint(medium up) {
                    flex-direction: column;
                    justify-content: center;
                }
            }

            @include breakpoint(medium up) {
                width: percentage(1/4);
            }

            &.disabled {
                img,span {
                    opacity: 0.5;
                }
            }

            img {
                margin-right: 1rem;
                margin-bottom: 0;

			@include breakpoint(medium up) {
                    margin-right: 0;
                    margin-bottom: 1rem;
                }
            }

            span {
                text-align: center;
                font-weight: $global-weight-light;
                font-size: rem-calc(15px);
                line-height: 1rem;
				color: $primary-color;
				transition: color 0.25s ease-in-out;
            }

            a {
                padding: 0;

                &:hover {
                    span {
                        color: $black;
                    }
                }
            }
        }
    }
}
