.c-job-form-request {
	div {
		margin: 0.25rem 0;
	}
	.job-title {
		font-size: 1.5rem;
		font-weight: bold;
		color: $primary-color;

		@include breakpoint(medium) {
			font-size: 2rem;
		}
	}

	.attachment-upload {
		margin-bottom: 0 !important;
	}

	.upload-row {
		display: flex;
		align-items: center;

		.upload-button {
			padding: 0.1rem 0.5rem;
			font-size: 1.5rem;
			margin-right: 1rem;
			margin-bottom: 0;
		}

		.upload-job-applications {
			display: flex;
			height: 7.5rem;
			text-align: center;
			width: 100%;
			align-items: center;
			justify-content: center;
			border: 1px solid $dark-gray;
			background-color: $light-gray;
		}
	}
}
