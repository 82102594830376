form.type3 {
    >.grid-container:not(.grid-spacing) {
        padding: 0 rem-calc(map-get($grid-container-padding, small) / 2);
    }
    .choose {
        @extend .font-rl;
        margin: 0;
        text-transform: uppercase;

        @include breakpoint(large down) {
            display: none;
        }
    }

    .partnerlist {
        display: flex;
        position: relative;

		@include breakpoint(xlarge) {
			aspect-ratio: 1175/286;
		}

		@include breakpoint(xxlarge) {
			aspect-ratio: 1410/191.55;
		}

        .partner-dropdown {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 1rem;
            margin: 0;
            font-size: 1.2rem;
            border-left: 0.2rem solid $primary-color;
            border-top: 0.2rem solid $primary-color;
            border-right: 0.2rem solid $primary-color;
            text-transform: unset;
            color: $placeholder-gray;

            @include breakpoint(xlarge) {
                font-size: 0.9rem;
                width: auto;
                max-width: rem-calc(300px);
                margin: 1rem 0;
            }

            @include breakpoint(xlarge) {
                display: none;
            }

            .du-icon-chevron-down {
                width: rem-calc(20px);
                height: auto;
                transition: transform 0.5s;
            }
        }

        #partner-toggler {
            display: none;
            visibility: hidden;
            @include breakpoint(large down) {
                &:checked {
                    ~ .partner {
                        visibility: visible;
                    }

                    ~ .partner-dropdown .du-icon-chevron-down {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .partner {
            position: absolute;
            top: 4rem;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            visibility: hidden;
            background-color: $white;
            box-shadow: rem-calc(5px) rem-calc(10px) rem-calc(25px) 0 rgba(0, 0, 0, 0.75);
            z-index: $spinner-zindex;

            @include breakpoint(xlarge) {
                position: relative;
                top: 0;
                visibility: visible;
                box-shadow: none;
                z-index: auto;
            }

            .form-check-label {
                cursor: pointer;
                width: percentage(1/2);
                padding: 1rem;

                @include breakpoint(medium) {
                    width: percentage(1/4);
                }

                @include breakpoint(xlarge) {
                    width: percentage(1/8);

					.image {
						width: 100%;
					}

					.image-zoom {
						transform: scale(1.2);
						transition: transform 0.2s ease-in;
					}
                }

                @include breakpoint(xxlarge) {
                    width: percentage(1/13);
                    margin: 0;
                    padding: 1rem;

                    .image {
                        img {
                            max-width: 100%;
                            // IE11 Fix
                            pointer-events: none;

	                        // Firefox Fix
	                        &::selection {
		                        background: transparent;
	                        }
                        }
                    }
                }
            }
        }
    }
}
