@import "../Patches/foundation.reveal";

// Overrides
.grid-container {
	margin-bottom: 1rem;
	&.no-margin {
		margin-bottom: 0;
	}
}

.grid-container.narrow {
	max-width: $grid-row-width * 2 / 3;
}

.grid-container.fluid.context,
.grid-container .grid-container:not(.grid-spacing) {
	padding: 0;
}

.bel-home .c-stage-slider,
.bel-come .c-stage-image,
.bel-home .c-stage-text {
	margin-bottom: 1rem;
}
