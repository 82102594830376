// Buttons

@mixin button-color($color: $black, $hoverBgColor: $primary-color, $hoverColor: $white) {
    border-color: $color;
    color: $color;

    &:hover {
        color: $hoverColor;
        background: $hoverBgColor;
		border-color: $hoverColor;
    }
}
