.card {
	img {
		margin-bottom: 1.5rem;
	}

	h2 {
		font-size: rem-calc(26px);
		margin-bottom: 0.75rem;
	}
}

.card.image-left {
	@include breakpoint(large) {
		flex-direction: row;
		align-items: start;

		> * {
			flex: auto;
		}

		.card-section {
			width: 66.66%;
			padding-left: rem-calc(10px);
			padding-top: rem-calc(10px);
		}

		img {
			margin-bottom: 0;
		}

		> a,
		> img,
		> picture {
			width: 33.33%;
			padding-right: rem-calc(10px);
		}
	}
}