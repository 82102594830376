.c-stage-image,
.c-stage-slider {
	picture {
		position: relative;
		display: block;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			display: block;
			background: rgba($black, 0.4);
		}
	}
}

