$table-border: 2px solid $primary-color;

table {
	display: block;
	width: 100%;
	overflow-x: auto;

	@include breakpoint(large) {
		display: table;
		overflow-x: visible;
	}

	tr {
		vertical-align: top;
	}

	td, th {
		min-width: rem-calc(200px);

		@include breakpoint(medium) {
			min-width: 0;
		}
	}

	th {
		@extend .header-font;
		font-size: rem-calc(20px);
		text-align: left;
		color: $primary-color;
	}

	thead {
		th {
			border-bottom: $table-border;
		}
	}

	tbody {
		tr:last-child td,
		tr:last-child th {
			border-bottom: $table-border;
		}
	}
}