$job-detail-spacer: rem-calc(30px);

.c-job-details {
	.spacer-blue {
		margin: $job-detail-spacer 0;
	}

	div {
		margin: $job-detail-spacer 0;
	}

	.stage-container {
		margin: 0;
		width: 100vw;
	}

	h3, h4, p, a, img {
		margin-bottom: 0;
		position: relative;
	}

	.overlay-container {
		margin: 0 -15px;
		padding: $job-detail-spacer 15px;
		position: relative;
	}

	.background-primary {
		color: $white;
		background-color: $primary-color;

		h1, h2, h3, h4, h5, h6 {
			color: $white;
		}
	}
	.background-secondary {
		color: $primary-color;
		background-color: $primary-color-lightest;
	}

	.criteria {
		display: flex;
		flex-wrap: wrap;
		margin-left: -0.5rem;
		margin-right: -0.5rem;

		&__item {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			width: 100%;

			@include breakpoint(medium) {
				width: 50%;
			}

			li {
				&:before {
					content: "\e907" !important;
					font-size: .88889rem !important;
					background-color: transparent !important;
					top: 0.3rem !important;
					color: $primary-color;
					font-family: $icon-font-family;
					speak: none;
					font-style: normal;
					font-weight: bold;
					font-variant: normal;
					text-transform: none;
					line-height: 1;
				}
			}
		}
	}

	.benefits {
		text-align: center;

		h3 {
			margin-bottom: 1rem;
		}

		.icon-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-left: -0.5rem;
			margin-right: -0.5rem;
			margin-top: 0;

			@include breakpoint(small) {
				margin-top: $job-detail-spacer;
			}

			.icon-item {
				padding: 0 0.5rem;
				margin: 2rem 0 0 0;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;

				@include breakpoint(smedium) {
					width: 50%;
				}

				@include breakpoint(medium) {
					width: calc(100% / 3);
				}

				@include breakpoint(large) {
					width: 20%;
				}

				i {
					font-size: 3rem;
				}

				p {
					margin-top: 1.5rem;
				}
			}
		}
	}
}
