.c-benefits {
	text-align: center;
	background: $light-gray;

	.header {
		padding: 1rem;
		background: $body-font-color;

		h2 {
			margin-bottom: 0;
			color: $white;
			text-transform: uppercase;
			font-size: rem-calc(20px);

			@include breakpoint(medium) {
				font-size: rem-calc(24px);
			}
		}
	}

	.content {
		padding: rem-calc(30px) 0;

		@include breakpoint(large) {
			display: flex;
			padding: rem-calc(50px) 0;
		}
	}

	.benefit {
		width: 100%;
		padding-bottom: rem-calc(30px);
		border-bottom: 1px solid $medium-gray;
		margin-bottom: rem-calc(30px);

		@include breakpoint(large) {
			padding-bottom: 0;
			border-right: 1px solid $medium-gray;
			border-bottom: none;
			margin-bottom: 0;
		}

		img {
			margin-bottom: rem-calc(25px);
		}

		&:last-child {
			padding-bottom: 0;
			border: none;
			margin-bottom: 0;
		}
	}
}