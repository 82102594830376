.c-teaser-career-container {
    .c-accordion {
        margin-top: rem-calc(40px);

        @include breakpoint(large) {
            margin-top: 0;
        }
    }
}

.c-accordion .c-teaser-career.content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        text-align: center;
        background: $light-gray;

        @include breakpoint(medium) {
            width: 33.33%;
        }

        > a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        p {
            margin-bottom: 0;

            &.description {
                margin-top: 0.5rem;
            }
        }

        span.header {
            @extend .h1;
            margin-bottom: 0;
        }

        > .text {
            padding: 1rem;
        }

        &.type-2 {
            color: $white;
            background: $accent-color;

            span.header {
                color: $white;
            }

            > a {
                color: $white;

                &:hover {
                    background: $primary-color;
                }
            }
        }

        &.type-img {
            img {
                width: 100%;
            }
        }

        &:last-child {
            width: 100%;

            @include breakpoint(medium) {
                width: 66.66%;
            }

            > .text {
                padding: 2rem 1rem;

                @include breakpoint(medium) {
                    padding: 1rem;
                }
            }

            span.header {
                font-size: rem-calc(32px);
                margin-bottom: 0;

                @include breakpoint(medium) {
                    font-size: rem-calc(48px);
                }
            }
        }
    }

    .box {
        &:nth-child(1) {
            order: 1;
        }
        &:nth-child(2) {
            order: 4;
        }
        &:nth-child(3) {
            order: 2;
        }
        &:nth-child(4) {
            order: 3;
        }
        &:nth-child(5) {
            order: 5;
        }

        @include breakpoint(medium) {
            order: 0 !important;
        }
    }
}