footer.main {
    background-color: $light-gray;

    .c-footer {
        padding-bottom: 1.5rem;
        background-color: $light-gray;

        @include breakpoint(medium) {
            padding-bottom: 1rem;
        }

        @include breakpoint(large) {
            padding-bottom: 2rem;
        }
    }

    .pipe {
        padding: 0 0.5rem;
    }

    .copyright {
        width: 100%;
        text-align: center;
        font-weight: $global-weight-normal;

        @include breakpoint(medium) {
            text-align: left;
        }
    }
}
