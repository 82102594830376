.c-stage-slider.c-full-width-slider {
	background-color: $primary-color;

	@include breakpoint(large) {
		background-color: $light-gray;
	}

	section {
		@include breakpoint(medium) {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			right: 0;
			margin: 0 auto;
		}
	}

	.content {
		width: 100%;

		@include breakpoint(medium) {
			padding: 0 4rem;
		}

		@include breakpoint(xxlarge) {
			padding: 0;
		}
	}

	h2, h3 {
		text-align: left;
		color: $white;

		@include breakpoint(medium) {
			&.color-white {
				color: $white;
			}

			&.color-black {
				color: $black;
			}
		}
	}

	h2 {
		margin-bottom: 0;
		padding-top: rem-calc(40);
		font-size: 1.5rem;
		text-transform: uppercase;
		line-height: 1.1;

		@include breakpoint(medium) {
			padding-top: 0;
			margin-bottom: 1rem;
			font-size: 2.5rem;
		}

		@include breakpoint(large) {
			font-size: rem-calc(56px);
		}

		@include breakpoint(xlarge) {
			margin-bottom: rem-calc(30px);
			font-size: rem-calc(72px);
		}
	}

	h3 {
		padding: 1rem 0;
		font-size: 1rem;

		@include breakpoint(medium) {
			padding: 0 0 1rem 0;
			font-size: 1.1rem;
		}

		@include breakpoint(large) {
			padding: 1rem 0;
			font-size: 1.3rem;
		}
	}

	.dots {
		@include breakpoint(medium) {
			display: none;
		}
	}

	.arrow {
		@include breakpoint(medium) {
			display: inline-block !important;
		}
	}
}

