/**
 * Preventing FOUC
 * (Flash of unstyled content)
 */

.no-js {
	@include breakpoint(medium down) {
		.top-bar {
			display: none;
		}
	}

	@include breakpoint(large) {
		.title-bar {
			display: none;
		}
	}
}
