#top-bar {
    height: rem-calc(120px);
    padding: 0;
    margin-bottom: 0;
}

.title-bar-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

html.nav-open,
html.nav-open body {
	width: 100%;
	min-height: 100%;
	overflow: hidden;
	position: fixed;
	user-select: none;
}

#mobile-bar {
    position: fixed;
    top: 4rem;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 4rem);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    z-index: $z-index-submenu;
    align-items: center;
    overflow: scroll;
    background-color: $primary-color-light;

    @include breakpoint(large up) {
        top: 7.1rem;
    }

    .title-bar-title {
        display: none;
    }

    >.menu {
        width: 100%;
        background-color: $primary-color-light;
        padding: .3rem;

        @include breakpoint(small down) {
            justify-content: flex-start;
        }
    }

    >.menu-mobile {
        position: absolute;
        top: 3rem;
        flex-wrap: wrap;
        padding: 0;
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 3rem;

        > li {
            background-color: $white;
            border-bottom: 1px solid $primary-color;

            a {
                padding: 0 rem-calc(15px) 1.1rem rem-calc(15px);
                font-weight: $global-weight-normal;
            }

            > a {
                padding: 1.1rem rem-calc(15px);
            }

            button:before {
                display: none;
            }
        }
    }

    .submenu-toggle {
        height: rem-calc(58px);
        width: rem-calc(50px);

        &:after {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-family: $icon-font-family;
            border: none;
            content: "\e900";
            transition: transform .5s;
        }

        &[aria-expanded='true']::after {
            transform: rotate(180deg);
        }
    }
}

.title-bar {
    padding: 0;
    background-color: $white;
    border-bottom: 1px solid $black;
    height: 4rem;

    .title-bar-left {
        height: 100%;
        display: flex;
        align-items: center;
    }

    > .grid-container {
        width: 100%;

        .menu-icon-container {
            display: flex;
            align-items: center;
        }
    }

    .c-logo {
        margin-bottom: -1rem;

        @include breakpoint(large) {
            margin-bottom: -3rem;
        }
    }

    .menu-icon::after {
        background-color: $primary-color;
        box-shadow: 0 7px 0 $primary-color, 0 14px 0 $primary-color;
    }
}
