// Accordion
.c-accordion {
	width: 100%;
}

.accordion-item {
	margin-bottom: rem-calc(1px);

	> a {
		@extend .header-font;
		background: $primary-color;

		&:before {
			@extend .du-icon;
			content: '\e900';
			transition: all 0.2s ease;
		}

		span {
			display: block;
		}
	}

	> a:focus {
		color: $white;
		background: $primary-color;
	}

	&.is-active > a:before {
		content: '\e900';
		transform: rotate(180deg);
	}

	&.is-active > a,
	> a:hover {
		color: $white;
		background: $secondary-color;
	}
}

// Tabs

.tabs-title {
	margin-right: rem-calc(10px);

	&:last-child {
		margin-right: 0;
	}

	> a {
		@extend .font-bd;
		border: rem-calc(2px) solid $body-font-color;
		text-transform: uppercase;

		&:hover, &:focus {
			color: $white;
		}
	}
}

// Accordion & Tabs

.c-accordion {
	.content {
		width: 100%;

		@include breakpoint(medium) {
			display: flex;
			justify-content: flex-end;
			flex-direction: row-reverse;
		}

		.img {
			width: 100%;
			margin-bottom: rem-calc(20px);

			@include breakpoint(medium) {
				max-width: rem-calc(450px);
				margin-left: rem-calc(20px);
			}
		}

		.text {
			width: 100%;
		}
	}
}

// Layouts
.c-accordion {
	&__layout2 {
		.accordion-item {
			border-bottom: rem-calc(1px) solid $dark-gray;
			margin-bottom: 0;

			&:last-child {
				border-bottom: 0;
			}

			> a {
				color: $primary-color;
				border-bottom: rem-calc(1px) solid transparent;
				background: transparent;

				&:before {
					@extend .du-icon;
					content: '\e900';
					transition: all 0.2s ease;
				}
			}

			> a:focus {
				color: $primary-color;
				background: transparent;
			}

			> a:hover {
				color: $secondary-color;

				&:before {
					color: $secondary-color;
				}
			}

			&.is-active > a:before {
				content: '\e900';
				transform: rotate(180deg);
				color: $secondary-color;
			}

			&.is-active > a,
			&.is-active > a:hover {
				color: $secondary-color;
				border-bottom: rem-calc(1px) solid $secondary-color;
				background: transparent;
			}
		}
	}
}


