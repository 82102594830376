.c-contact {
	background: $light-gray;

	@include breakpoint(smedium) {
		display: flex;
	}

	> .text {
		padding: rem-calc(20px);

		@include breakpoint(smedium) {
			padding: rem-calc(15px 20px 5px 20px);
		}
	}

	> .img {
		@include breakpoint(smedium) {
			flex-shrink: 0;
			width: rem-calc(200px);
		}
	}

	.head {
		margin-bottom: 0.5rem;
	}

	h3 {
		margin-bottom: 0;
		font-size: rem-calc(18px);
	}

	p {
		margin-bottom: 0;
		font-size: $small-font-size;
		overflow-wrap: break-word;
	}

	.frame-layout-1 & {
		background-color: #fff;

		> .text {
			@include breakpoint(smedium) {
				padding: rem-calc(84px 20px 15px 20px);
			}
		}

		h3 {
			font-family: $light-font-family;
			font-size: rem-calc(31px);
			line-height: 1;
			color: $primary-color;
			font-weight: 400;
			margin-bottom: rem-calc(10px);
		}

		p {
			font-family: $light-font-family;
			font-weight: 400;
			font-size: rem-calc(15px);
			color: $black;
			line-height: rem-calc(20px);
		}
	}
}
