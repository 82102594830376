.reveal-overlay {
    max-width: $global-max-width;
    margin: 0 auto;
    background-color: rgba($white,0.8);
    position: fixed;
    top: 0;
    height: 100%;
    z-index: $z-index-menu;
    overflow-y: auto;

    @include breakpoint(large up) {
        height: calc(100% - 6.7rem);
        z-index: $z-index-submenu;
        top: 6.7rem;
        left: calc((100% - #{$global-max-width}) / 2);
        right: calc((100% - #{$global-max-width}) / 2);
    }



    > div {
        position: static;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 3rem;
        padding-bottom: 3rem;

        @include breakpoint(medium up) {
            justify-content: flex-start;
            padding-top: 5rem;
            padding-bottom: 0;
        }
    }

    h2 {
        font-size: rem-calc(30px);
        text-transform: uppercase;
        text-align: center;
		color: $primary-color;

        @include breakpoint(medium up) {
            font-size: rem-calc(42px);
        }
    }

    button {
        padding: 0.6rem 1rem;
        cursor: pointer;
        font-weight: $global-weight-light;
        text-transform: uppercase;
        font-size: 0.9rem;
        width: 100%;
        margin: 0;


		&[type=submit] {
			border-color: $primary-color;
			border-radius: 0.5rem;
		}

        @include breakpoint(medium up) {
            width: auto;
        }
    }

    .button-close {
        position: fixed;
        top: auto;
        bottom: 0;
        right: 0;
        left: 0;

        @include breakpoint(medium up) {
            position: fixed;
            top: auto;
            bottom: 2.5rem;
            right: 2.5rem;
            left: auto;
        }
    }
}

.reveal {
    background-color: transparent;
    border: none;
    max-width: 100%;
}
