$steps-legend-height-small: rem-calc(40);
$steps-legend-height-medium: rem-calc(45);

form.steps {
    padding: 0;
    margin: 0;
    background: transparent;

    .step {

    }

    fieldset {
        margin-bottom: 0;
    }

    legend {
        width: 100%;

        span.container {
            display: flex;
            align-items: center;
            width: 100%;
        }

        span.count {
            display: inline-block;
            flex-shrink: 0;
            width: $steps-legend-height-small;
            height: $steps-legend-height-small;
            margin-right: rem-calc(15);
            text-align: center;
            line-height: $steps-legend-height-small;
            color: $primary-color;
            background: $primary-color-light;

            @include breakpoint(medium) {
                width: $steps-legend-height-medium;
                height: $steps-legend-height-medium;
                margin-right: rem-calc(20);
                line-height: $steps-legend-height-medium;
            }
        }

        span.title {
            display: inline-block;
            line-height: 1.1;
            text-transform: uppercase;
        }
    }

    .content {
        padding: rem-calc(15);
        margin: 0 calc(-1 * #{rem-calc(map-get($grid-container-padding, small))} / 2) rem-calc(40);
        background: $light-gray;

        @include breakpoint(medium) {
            padding: rem-calc(40 40 20 40);
            margin: 0 0 rem-calc(60) 0;
        }

        &.hidden-content {
            display: none;
        }

        &.show-content {
            display: block;
        }
    }

    .step.active {
        legend span.count {
            color: $white;
            background: $accent-color;
        }
    }

    .navigation {
        padding-top: rem-calc(40);
        border-top: 1px solid rgba($body-font-color, 0.3);
        margin-top: rem-calc(40);

        > .input.checkbox {
            margin-top: rem-calc(-20);
            margin-bottom: rem-calc(40);
        }

        .button {
            margin-left: rem-calc(10);

            &:first-child {
                margin-left: 0;
            }
        }
    }
}