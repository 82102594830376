.ce-textpic {
	.ce-row {
		@include flex;
		@include flex-direction(column);

		@include breakpoint(medium) {
			@include flex-direction(row);
		}
	}

	.ce-column {
		margin: rem-calc(0 0 20 0);

		@include breakpoint(medium) {
			margin: rem-calc(0 10 20 10);

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	// Gallery Above
	&.ce-above .ce-gallery {
		margin-bottom: rem-calc(10);
	}

	// Gallery Below
	&.ce-below .ce-gallery {
		margin-top: rem-calc(30);
	}

	// Gallery Intext
	&.ce-intext {
		@include xy-grid;
		@include xy-margin-grid-classes;
		@include grid-row-nest($grid-margin-gutters);

		.ce-gallery {
			margin-bottom: rem-calc(20);
		}

		> div {
			@include xy-cell();
		}

		> div.ce-bodytext,
		> div.ce-gallery {
			@include breakpoint(medium) {
				@include xy-cell(6);
			}
		}
	}

	// Gallery Left
	&.ce-left {

	}

	// Gallery Right
	&.ce-right {
		@include breakpoint(medium) {
			@include flex-direction(row-reverse);
		}
	}

	// Border
	.ce-border {
		iframe,
		object,
		embed,
		video,
		audio,
		img,
		picture {
			border: 1px solid $primary-color;
		}
	}

	// Video

	.responsive-embed {
		margin-bottom: 0;
	}

	.ce-gallery {
		.ce-outer,
		.ce-inner,
		.ce-column,
		figure {
			width: 100%;
		}
	}
}

// IE Bugfix

.trident .ce-gallery img {
	width: 100%;
}
