.c-job-list {
	display: flex;
	flex-wrap: wrap;
	margin-left: -0.5rem;
	margin-right: -0.5rem;

	&__item {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		margin-bottom: 1rem;
		width: 100%;

		@include breakpoint(large) {
			width: calc(100% / 2);
		}

		&--content {
			border: 2px solid $light-gray;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 1rem;

			@include breakpoint(large) {
				min-height: 17.5rem;
			}

			.headline {
				margin-bottom: 1rem;
				font-weight: bold;
			}

			p {
				margin-bottom: 0;
			}

			a {
				width: fit-content;
			}
		}
	}
}
