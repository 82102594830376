ul.pagination {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	li {
		width: 2rem;
		height: 2rem;
		margin-bottom: rem-calc(10px);
		text-align: center;
		line-height: 2rem;
		transition: all 0.3s ease;

		a {
			padding: 0;
		}

		&:hover,
		a:hover {
			padding: 0;
			color: $white;
		}

		&:last-child {
			margin-right: 0;
		}

		&.pagination-previous,
		&.pagination-next {
			width: auto;

			a {
				padding: 0 0.5rem;

				&:before,
				&:after {
					margin: 0;
				}
			}
		}
	}

	li.current {
		padding: 0;

		a {
			color: $pagination-item-color-current;
		}
	}
}

div.pagination {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 2rem;

	@include breakpoint(smedium) {
		flex-wrap: nowrap;
	}

	form {
		width: calc(50% - 0.5rem);

		&.next {
			margin-left: 0.5rem;
		}

		&.prev {
			margin-right: 0.5rem;
		}

		@include breakpoint(medium) {
			width: auto;
		}

		.button	{
			width: 100%;
		}
	}

	p {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin: 0 0 1rem 0;

		@include breakpoint(smedium) {
			margin: 0;
		}
	}
}

