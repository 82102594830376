[class^="du-icon-"], [class*=" du-icon-"], .du-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $icon-font-family;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: .9rem;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.du-icon-arrow-down:before {
    content: "\e900";
}
.du-icon-arrow-left:before {
    content: "\e901";
    margin-left: 1rem;
}
.du-icon-arrow-right:before {
    content: "\e902";
}
.du-icon-arrow-up:before {
    content: "\e903";
}
.du-icon-check:before {
    content: "\e904";
}
.du-icon-chevron-down:before {
    content: "\e905";
}
.du-icon-chevron-left:before {
    content: "\e906";
}
.du-icon-chevron-right:before {
    content: "\e907";
}
.du-icon-chevron-up:before {
    content: "\e908";
}
.du-icon-close:before {
    content: "\e909";
}
.du-icon-Dental-Union-Signet:before {
    content: "\e90a";
}
.du-icon-language:before {
    content: "\e90b";
}
.du-icon-minus:before {
    content: "\e90c";
}
.du-icon-pin:before {
    content: "\e90d";
}
.du-icon-plus:before {
    content: "\e90e";
}
.du-icon-search:before {
    content: "\e90f";
}
.du-icon-shape-1:before {
    content: "\e910";
}
.du-icon-shape-2:before {
    content: "\e911";
}
.du-icon-shape-3:before {
    content: "\e912";
}
.du-icon-shape-4:before {
    content: "\e913";
}