#app-depot {
    position: relative;

    .spinner-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($dark-gray, 0.6);
        z-index: $spinner-zindex;
    }

    .depot-finder-container {
        position: relative;
        top: 0;
        padding-bottom: 2.25rem;
        pointer-events: none;

		.placeholder-text {
			display: none;
		}

        @include breakpoint(xlarge) {
            position: absolute;
            top: 100%;
			left: 0;
			right: 0;
            padding-top: 2.25rem;

			.placeholder-text {
				align-items: center;
				background-color: $primary-color;
				color: $white;
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: center;
				padding: 2rem;
				pointer-events: auto;

				h3 {
					color: $white;
				}

				.button {
					border-color: $white;
					width: fit-content;

					&:hover {
						background-color: $white;
					}
				}
			}
        }
    }

    form {
        z-index: 10;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

	.map-container {
		position: relative;

		.map-placeholder {
			display: none;
		}

		@include breakpoint(xlarge) {
			height: 790px;
			width: 100%;

			.map-placeholder {
				display: block;
				height: rem-calc(790);
				inset: 0;
				overflow: hidden;
				position: absolute;
				z-index: 2;
			}

			.map-placeholder img {
				height: 100%;
				object-fit: cover;
				width: 100%;
			}
		}
	}

	.deactivate-map {
		display: none;

		@include breakpoint(xlarge) {
			margin-top: 1rem;
			display: block;
			width: 100%;
			text-align: center;
		}
	}

    #map {
        display: none;
        @include breakpoint(xlarge) {
            display: block;
        }
    }
}
