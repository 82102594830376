@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=0c9c0735-b843-4934-b884-d8f97c9b30fd");
@font-face{
	font-family: 'Helvetica Neue CB';
	src:url("../Fonts/02980b3d-1e37-4378-a5c6-4cf0d6ce0ba5.woff2") format("woff2"),url("../Fonts/2d1e8a5f-eb7a-47a1-be1d-878bb9a46b6e.woff") format("woff");
}
@font-face{
	font-family:"Helvetica Neue Light";
	src:url("../Fonts/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2") format("woff2"),url("../Fonts/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff") format("woff");
}
@font-face {
    font-family: 'Dental-Union';
    src: url('../Fonts/dental_union.eot') format('woff');
    src: url('../Fonts/dental_union.woff') format('woff'),
         url('../Fonts/dental_union.ttf') format('truetype'),
         url('../Fonts/dental_union.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

