.c-document-downloads {
	.row {
		display: flex;
		flex-wrap: wrap;
		margin-left: -0.5rem;
		margin-right: -0.5rem;
		width: calc(100% + 1rem);
	}

	.column {
		padding: 1rem 0.5rem;
		width: 100%;

		@include breakpoint(medium) {
			width: calc(100% / 2);
		}

		@include breakpoint(large) {
			width: calc(100% / 3);
		}

		@include breakpoint(xlarge) {
			width: calc(100% / 4);
		}
	}

	.item {
		border: 2px solid $light-gray;

		&-image-container {
			display: flex;
			align-items: center;
			height: 12.5rem;
			justify-content: center;
			background-color: $light-gray;

			a {
				max-height: 100%;
			}

			img {
				height: 12rem;
				width: auto;
				transition: all 0.25s linear;
				background-color: $white;

				&:hover {
					transform: scale(1.1);
					box-shadow: 0 0 5px 1px $dark-gray;
				}
			}

			.logo {
				padding: 4rem 2rem 2rem 2rem;
				background-color: $primary-color;
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0.5rem 0.5rem 0;

		@include breakpoint(medium) {
			min-height: 15rem;
		}

		@include breakpoint(xlarge) {
			min-height: 17.5rem;
		}

		.info {
			.description {
				margin-top: 0.25rem;
				margin-bottom: 0.25rem;
			}
		}

		.meta {
			border-top: 2px solid $light-gray;
			display: flex;
			justify-content: space-between;
			padding: 0.25rem 0 0;

			.download {
				display: flex;
				justify-content: center;

				a {
					padding: 0.25rem 0.5rem 0 0;

					svg {
						fill: $primary-color;
						height: 1rem;
						width: 1rem;
					}

					&:hover {
						svg {
							transform: scale(1.2);
							fill: $secondary-color;
						}
					}
				}
			}

		}
	}

	.content-height {
		min-height: 7.5rem;
	}
}
