.tx-kesearch-pi1 {
	.result-list-item {
		padding-bottom: 1rem;
		border-bottom: 1px solid $primary-color;
		margin-bottom: 1rem;
	}

	span.hit {
		@extend .font-bd;
	}

	.messsage {
		margin-bottom: 2rem;
	}
}

#ke_search_sword {
	position: relative !important;
	max-width: rem-calc(300px);
	margin-right: rem-calc(20px) !important;
}
