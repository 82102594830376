header.main {
    max-width: $global-max-width;
    margin: 0 auto;
    height: 4rem;

    @include breakpoint(large) {
        height: auto;
    }

    .top-bar {
        background-color: $white;
    }
}

.main-header {
    margin-bottom: 0;
}
