main {
    padding-top: 4rem;
    @include breakpoint(large up) {
        padding-top: 6.7rem;
    }

	&.blur {
		filter: blur(6px);
	}
}
