// Errors / Abide

.is-invalid-input:not(:focus) {
    background-color: $white;
}

.is-invalid-label {
    color: $form-label-color-invalid;
}

input.is-invalid,
select.is-invalid,
textarea.is-invalid {
    @extend .is-invalid-input;
}

label.is-invalid {
    @extend .is-invalid-label;
}

.form-check-label.is-invalid-label {
    input[type="checkbox"],
    input[type="radio"] {
        + span:before {
            border-color: $form-label-color-invalid;
        }
    }
}

.form-check > label.add-on.is-invalid-label {
    color: $form-label-color-invalid;
}

.button.is-invalid {
    border-color: $form-label-color-invalid;

    &:hover {
        background: $form-label-color-invalid;
    }
}

.error-message {
    color: $input-error-color;
    font-size: $input-error-font-size;
    font-weight: $input-error-font-weight;
}