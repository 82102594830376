#global-search {
	padding: 5rem 1rem;

    .c-global-search__form {
        .input-group {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .input-group-field {
                max-width: rem-calc(700px);
				min-height: 3rem;
				border-color: $primary-color;
            }

            .input-group-button {
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-top: 2rem;

                @include breakpoint(medium up) {
                    flex-direction: row;
                }

				&[type="submit"] {
					border-color: $white;
				}

                .cancel-button {
                    display: none;
                    margin-top: 0.5rem;
					color: $primary-color;

                    @include breakpoint(medium up) {
                        display: block;
                        margin-top: 0;
                    }

                    &:hover {
						color: $secondary-color;
                    }
                }
            }
        }
    }
}
