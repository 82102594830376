.youtube-video-container {
	iframe {
		display: none;
	}
	.youtube-placeholder {
		aspect-ratio: 16/9;
		align-items: center;
		background: url("/typo3conf/ext/site/Resources/Public/Images/Placeholder/youtube-placeholder.jpg") center center no-repeat;
		background-size: cover;
		color: $white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 1rem;
		text-align: center;

		h3 {
			color: $white;
			margin-bottom: 0.5rem;

			@include breakpoint(medium) {
				margin-bottom: 1rem;
			}
		}

		p {
			font-size: 0.5rem;
			margin-bottom: 0;

			@include breakpoint(medium) {
				font-size: 0.8rem;
				margin-bottom: 1rem;
			}

			@include breakpoint(large) {
				font-size: 1rem;
			}
		}

		.button {
			border-color: $white;
			width: fit-content;
			padding: 0.5rem;
			font-size: 0.8rem;

			@include breakpoint(medium) {
				font-size: 1rem;
				padding: 1rem;
			}

			&:hover {
				background-color: $white;
				color: $primary-color;
			}
		}
	}
}
