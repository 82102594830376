.c-parallax-scroller {
	position: relative;
	min-height: 16rem;
	margin-bottom: 4rem;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	&__text{
		@include absolute-center;
		color: $white;
	}
}
