// Fieldsets

fieldset {
    margin-bottom: rem-calc(20px);

    legend {
        @extend .header-font;
        @extend .h3;

        font-size: rem-calc(22);

        @include breakpoint(medium) {
            font-size: rem-calc(26);
        }
    }
}

// Label

label {
    margin-bottom: rem-calc(16);
    text-transform: uppercase;
}

// Textareas

textarea {
    min-height: rem-calc(100px);
}

// Checkboxes and Radios

.inputs-list {
    input[type="checkbox"],
    input[type="radio"] {
        margin-bottom: 0;
    }
}

.inputs-list {
    .form-check:last-child {
        input[type="checkbox"],
        input[type="radio"] {
            margin-bottom: $form-spacing;
        }
    }
}

.inputs-list.inline {
    @include breakpoint(medium) {
        .form-check {
            display: inline-block;
            width: auto;
            margin-right: rem-calc(40);

            &:last-child {
                margin-right: 0;
            }
        }

        .form-check-label {
            display: inline-flex;
        }
    }
}

.inputs-list.col-2 {
    .form-check {
        @include breakpoint(medium) {
            display: inline-block;
            width: 50%;
            padding-right: rem-calc(30px);
        }
    }
}

.form-check-label {
    display: flex;
    align-items: start;
    position: relative;

    input[type="checkbox"],
    input[type="radio"] {
        visibility: hidden;
        position: absolute;
    }

    input[type="checkbox"],
    input[type="radio"] {
        + span {
            line-height: $base-line-height;
            margin-left: rem-calc(30);

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: rem-calc(20);
                height: rem-calc(20);
                border: $input-border;
                margin-top: rem-calc(2);
                background: $white;
            }

            &:after {
                border: none;
                width: rem-calc(12);
                height: rem-calc(12);
                margin: rem-calc(6 4 4 4);
                background: $white;
                transition: all 0.2s ease;
            }
        }

        &:checked + span:after {
            background: $secondary-color;
        }
    }

    input[type="radio"] {
        + span:before,
        + span:after {
            border-radius: 50%;
        }
    }

    input[type="checkbox"] {
        + span:after {
            background-repeat: no-repeat;
            background-position: center center;
        }

        &:checked + span:after {
            background-color: $white;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='12px' height='9px' viewBox='0 0 12 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Layout' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Forms' transform='translate(-434.000000, -2202.000000)' fill='%23009FE3'%3E%3Cpolygon id='Fill-1-Copy-2' points='444.431846 2202 443.643375 2202.76704 438.245403 2208.07672 435.386299 2205.82674 434 2207.53127 437.639035 2210.39488 438.41015 2211 439.120629 2210.30968 446 2203.54283'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
    }
}

// Uploads

input[type="file"] {
    font-size: $form-label-font-size;
}

.file-upload {
    position: relative;
    display: inline-block;
    margin-bottom: rem-calc(5);
    overflow: hidden;

    input[type="file"] {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
    }
}

.file-upload-container {
    .info-text {
        display: block;

        &.filename {
            max-width: 100%;
            width: rem-calc(275px);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}



// Form type 2

form.type2 {
    padding: rem-calc(15 15 0 15);
    margin: 0 calc(-1 * #{rem-calc(map-get($grid-container-padding, small))} / 2);
    background: $light-gray;

    @include breakpoint(medium) {
        padding: rem-calc(40 40 20 40);
        margin: 0;
    }

    @include breakpoint(large) {
        padding: rem-calc(60 60 40 60);
    }

    input, select, textarea {
        margin-bottom: 0;
    }

    label {
        margin: rem-calc(5 0);
    }

    .form-group > label {
        color: $primary-color;

        &.is-invalid-label {
            color: $form-label-color-invalid;;
        }
    }

    .form-check > label {
        color: $dark-gray;
    }

    .form-group {
        padding-bottom: rem-calc(15);
        margin-bottom: rem-calc(15);

        .form-group {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .form-group.separator {
        padding-bottom: rem-calc(20);
        border-bottom: 1px solid rgba($body-font-color, 0.3);
        margin-bottom: rem-calc(20);
    }
}
