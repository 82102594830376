.c-teaser-grid {
	.teaser-grid-item {
		@include breakpoint(medium) {
			&:nth-child(even) {
				> .grid-x {
					flex-direction: row-reverse;
				}
			}
		}
	}

	.inner {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.text {
		padding: rem-calc(20 30);
		margin-bottom: rem-calc(20);

		@include breakpoint(medium) {
			margin-bottom: 0;
		}

		@include breakpoint(large) {
			padding: rem-calc(40 50);
		}

		h2 {
			color: $secondary-color;
		}

		.link {
			margin: rem-calc(40) 0 0 0;

			@include breakpoint(medium) {
				margin-top: auto;
			}

			.button {
				margin: 0;
			}
		}



		&.dark {
			background: $primary-color;

			h3 {
				color: $white;
			}

			.button {
				@extend .negative;
			}
		}

		&.light {
			background: $primary-color-lightest;

			h3 {
				color: $primary-color;
			}
		}
	}
}
